<template>
  <div>
    <div class="row heading">
      <div class="col">
        <div @click="showFilingInfo = !showFilingInfo">
          <h2 class="header">Filing Info</h2>
        </div>
        <div class="box" v-if="showFilingInfo">
          <div class="row">
            <div class="col">
              <dt>Business Purpose</dt>
              <dd v-if="businessEntity.business_entity_data.business_purpose">
                <SlotCopy :valueToCopy="businessEntity.business_entity_data.business_purpose">
                  {{ businessEntity.business_entity_data.business_purpose }}
                </SlotCopy>
              </dd>
              <dd v-else>None on file.</dd>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <dt>EIN</dt>
              <dd v-if="businessEntity.business_entity_data && businessEntity.business_entity_data.ein">
                <SlotCopy :valueToCopy="businessEntity.business_entity_data.ein">
                  {{ businessEntity.business_entity_data.ein }}
                </SlotCopy>
              </dd>
              <dd v-else>None on file.</dd>
            </div>
            <div class="col">
              <dt>Business Industry</dt>
              <dd v-if="businessEntity.business_entity_data.business_industry">
                <SlotCopy :valueToCopy="businessEntity.business_entity_data.business_industry">
                  {{ businessEntity.business_entity_data.business_industry }}
                </SlotCopy>
              </dd>
              <dd v-else>None on file.</dd>
            </div>
            <div class="col">
              <dt>Authorized Signatory</dt>
              <dd v-if="businessEntity.business_entity_data.authorized_signatory && Object.keys(businessEntity.business_entity_data.authorized_signatory).length > 0">
                <SlotCopy :valueToCopy="this.computeFullNameOfPerson(businessEntity.business_entity_data.authorized_signatory)">
                  {{ this.computeFullNameOfPerson(businessEntity.business_entity_data.authorized_signatory) }}, {{ businessEntity.business_entity_data.authorized_signatory.title }}
                </SlotCopy>
              </dd>
              <dd v-else>None on file.</dd>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <dt>NAICS Code</dt>
              <dd v-if="businessEntity.naics_code">
                <SlotCopy :valueToCopy="businessEntity.naics_code.substring(0, 2)">
                  {{ businessEntity.naics_code.substring(0, 2) }} ({{ getCodeLabel(businessEntity.naics_code.substring(0, 2)) }})
                </SlotCopy>
              </dd>
              <dd v-else>None on file.</dd>
            </div>
            <div class="col-md-8">
              <dt>NAICS Subcode</dt>
              <dd v-if="businessEntity.naics_code">
                <SlotCopy :valueToCopy="businessEntity.naics_code">
                  {{ businessEntity.naics_code }} ({{ getCodeLabel(businessEntity.naics_code) }})
                </SlotCopy>
              </dd>
              <dd v-else>None on file.</dd>
            </div>
          </div>
          <div v-if="isCorp">
            <div class="row">
              <div class="col">
                <dt>Authorized Shares</dt>
                <dd v-if="businessEntity.business_entity_data.authorized_shares !== undefined">
                  {{ businessEntity.business_entity_data.authorized_shares }}
                </dd>
                <dd v-else>None on file.</dd>
              </div>
              <div class="col">
                <dt>Par Value</dt>
                <dd v-if="businessEntity.par_value !== undefined">
                  {{ businessEntity.par_value }}
                </dd>
                <dd v-else>None on file.</dd>
              </div>
              <div class="col">
                <dt>More than one class or series of shares</dt>
                <dd v-if="businessEntity.has_more_than_one_share !== undefined">
                  {{ businessEntity.has_more_than_one_share ? 'Yes' : 'No' }}
                </dd>
                <dd v-else>None on file.</dd>
              </div>
            </div>
          </div>
          <br>
          <CollapsiblePanel
            v-for="(jurisdiction, index) in jurisdictions"
            :key="index"
            :titleColumns="3"
            :titleBorder="true"
          >
            <template v-slot:title>
              <span>
                  <strong>Jurisdiction</strong>
                  <SlotCopy :valueToCopy="jurisdiction.jurisdiction_code">
                    {{ jurisdiction.jurisdiction_code }}
                  </SlotCopy>
                  {{ jurisdiction.domestic_flag ? '(Domestic)' : '' }}
              </span>
              <span :id="'has-zb-ra-container-' + jurisdiction.jurisdiction_code">
                <strong>RA</strong>
                <template v-if="jurisdictionsWithZbRa.includes(jurisdiction.jurisdiction_code)">

                  <i class="fas fa-check text-success"></i>
                </template>
                <template v-else>
                  <i class="fas fa-times text-danger"></i>
                </template>
                <span v-if="registeredAgentData(jurisdiction.jurisdiction_code, 'company_name')" class="text-smaller">
                  {{ registeredAgentData(jurisdiction.jurisdiction_code, 'company_name') }}
                </span>
              </span>
              <span>
                <em>
                  <SlotCopy :valueToCopy="jurisdictions[index].jurisdiction_entity_name">
                    {{ jurisdictions[index].jurisdiction_entity_name }}
                  </SlotCopy>
                </em>
              </span>
            </template>
            <template v-slot:content>
              <div class="row">
                <div class="col col-4">
                  <dt>Jurisdiction</dt>
                  <dd>
                    <SlotCopy :valueToCopy="jurisdiction.jurisdiction_code">
                      {{ jurisdiction.jurisdiction_code }}
                    </SlotCopy>
                    {{ jurisdiction.domestic_flag ? '(Domestic)' : '(Foreign)' }}
                  </dd>
                </div>

                <div class="col col-4">
                  <dt>Has ZB WFC?</dt>
                  <dd v-if="jurisdictionsWithZbWfc.includes(jurisdiction.jurisdiction_code)">
                    <i class="fas fa-check text-success"></i>
                  </dd>
                  <dd v-else><i class="fas fa-times text-danger"></i></dd>
                </div>

                <div class="col col-4">
                  <dt>Jurisdiction Entity Name</dt>
                  <dd>
                    <SlotCopy :valueToCopy="jurisdiction.jurisdiction_entity_name">
                      {{ jurisdiction.jurisdiction_entity_name }}
                    </SlotCopy>
                  </dd>
                </div>

                <div class="col col-4">
                  <dt>Has forced name ?</dt>
                  <dd v-if="jurisdiction.has_forced_name"><i class="fas fa-check text-success"></i></dd>
                  <dd v-else><i class="fas fa-times text-danger"></i></dd>
                </div>

                <div class="col col-4">
                  <dt>Business ID</dt>
                  <dd v-if="jurisdiction.jurisdiction_filing_number">
                    <SlotCopy :valueToCopy="jurisdiction.jurisdiction_filing_number">
                      {{ jurisdiction.jurisdiction_filing_number }}
                    </SlotCopy>
                  </dd>
                  <dd v-else>None on file.</dd>
                </div>

                <div class="col col-4">
                  <dt>Filing Date</dt>
                  <dd v-if="jurisdiction.jurisdiction_filing_date">
                    {{ jurisdiction.jurisdiction_filing_date }}
                  </dd>
                  <dd v-else>None</dd>
                </div>

                <div class="col col-4" v-if="jurisdiction.future_filing_date">
                  <dt>Future Filing Date</dt>
                  <dd v-if="jurisdiction.future_filing_date">
                    {{ jurisdiction.future_filing_date | moment("MMMM DD YYYY") }}
                  </dd>
                  <dd v-else>None on file.</dd>
                  <dt>Filing Action</dt>
                  <dd v-if="jurisdiction.future_filing_date">{{ jurisdiction.filing_action }}</dd>
                  <dd v-else>None on file.</dd>
                </div>

                <div class="col col-4">
                  <dt>Customer ID</dt>
                  <dd v-if="jurisdiction.customer_id">
                    <SlotCopy :valueToCopy="jurisdiction.customer_id">
                      {{ jurisdiction.customer_id }}
                    </SlotCopy>
                  </dd>
                  <dd v-else>None on file.</dd>
                </div>

                <div class="col col-4">
                  <dt>State PIN</dt>
                  <dd v-if="jurisdiction.pin">
                    <SlotCopy :valueToCopy="jurisdiction.pin">
                      {{ jurisdiction.pin }}
                    </SlotCopy>
                  </dd>
                  <dd v-else>None on file.</dd>
                </div>

                <div class="col col-4">
                  <dt>Other Credential</dt>
                  <dd v-if="jurisdiction.other_credential">{{ jurisdiction.other_credential }}</dd>
                  <dd v-else>None on file.</dd>
                </div>
                <!-- Render extra jurisdiction filing info on a state-by-state basis -->
                <template v-if="jurisdiction.jurisdiction_code === 'CA'">
                  <div class="col col-4">
                    <dt>Elective CEO</dt>
                    <dd v-if="jurisdiction.extra_state_info.elective_ceo && Object.keys(jurisdiction.extra_state_info.elective_ceo).length > 0">
                      <Person :person="jurisdiction.extra_state_info.elective_ceo" >
                      </Person>
                    </dd>
                    <dd v-else>None on file.</dd>
                  </div>
                  <div class="col">
                    <dt>Name reservation?</dt>
                    <dd v-if="jurisdiction.has_name_reservation"><i class="fas fa-check text-success"></i></dd>
                    <dd v-else><i class="fas fa-times text-danger"></i></dd>
                  </div>
                  <div class="col">
                    <dt>Name reservation number</dt>
                    <dd v-if="jurisdiction.name_reservation_number">{{jurisdiction.name_reservation_number}}</dd>
                    <dd v-else>None</dd>
                  </div>
                </template>
              </div>

              <div class="row">
                <div class="col col-12">
                  <h4 class="ra">Registered Agent</h4>
                </div>
              </div>

              <div class="row">
                <div class="col col-4" :id="'has-zb-ra-container-' + jurisdiction.jurisdiction_code">
                  <dt>Has ZB RA?</dt>
                  <dd v-if="jurisdictionsWithZbRa.includes(jurisdiction.jurisdiction_code)">
                    <i class="fas fa-check text-success"></i>
                  </dd>
                  <dd v-else><i class="fas fa-times text-danger"></i></dd>
                </div>

                <div class="col col-4" v-if="showExternalRaId(jurisdiction)">
                  <dt>External RA Company ID</dt>
                  <dd>
                    {{ jurisdiction.external_ra_company_id }}
                  </dd>
                </div>

                <template v-if="jurisdictionsWithZbRa.includes(jurisdiction.jurisdiction_code)">
                  <div class="col col-4">
                    <dt>RA Company Name</dt>
                    <dd v-if="registeredAgentData(jurisdiction.jurisdiction_code, 'company_name')">
                      {{ registeredAgentData(jurisdiction.jurisdiction_code, 'company_name') }}
                    </dd>
                    <dd v-else>None on file</dd>
                  </div>

                  <div class="col col-4">
                    <dt>RA Contact</dt>
                    <dd v-if="registeredAgentData(jurisdiction.jurisdiction_code, 'first_name')
                              || registeredAgentData(jurisdiction.jurisdiction_code, 'last_name')">
                        {{ registeredAgentData(jurisdiction.jurisdiction_code, 'first_name') }} {{ registeredAgentData(jurisdiction.jurisdiction_code, 'last_name') }}
                    </dd>
                    <dd v-else>None on file</dd>
                  </div>
                </template>
              </div>

              <div class="row" v-if="jurisdictionsWithZbRa.includes(jurisdiction.jurisdiction_code)">
                <div class="col col-4">
                  <dt>RA Address</dt>
                  <dd v-html="registeredAgentAddress(jurisdiction.jurisdiction_code)">
                  </dd>
                </div>

                <div class="col col-4">
                  <dt>RA Phone Number</dt>
                  <dd v-if="registeredAgentData(jurisdiction.jurisdiction_code, 'phone')">
                    <a :href="`tel:${registeredAgentData(jurisdiction.jurisdiction_code, 'phone')}`" class="link">
                      {{ registeredAgentData(jurisdiction.jurisdiction_code, 'phone') }}
                    </a>
                  </dd>
                  <dd v-else>None on file</dd>
                </div>

                <div class="col col-4">
                  <dt>RA Email</dt>
                  <dd v-if="registeredAgentData(jurisdiction.jurisdiction_code, 'email')">
                    <a :href="`mailto:${registeredAgentData(jurisdiction.jurisdiction_code, 'email')}`" target="_blank" class="link">
                      {{ registeredAgentData(jurisdiction.jurisdiction_code, 'email') }}
                    </a>
                  </dd>
                  <dd v-else>None on file</dd>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col col-8">
                  <!--                  This button is functional, but the table is full of bad and incomplete data creating uncertainty around what's shown-->
                  <!--                  We've chosen to hide this button pending history cleanup and backfill-->
                  <!--                  See OPERTOOL-119 (and associated tickets) for details-->
                  <!--                  2021-11-23-->
                  <div v-if="false">
                    <button @click="openRaHistory(jurisdiction)" type="button" class="btn btn-sm btn-light" :disabled="false">Show RA History</button>
                  </div>
                </div>
                <div class="col col-4">
                  <div v-if="canEditRAtoZBRegisterAgent() && needUpdateRAService(jurisdiction)">
                    <button @click="openRaModal(jurisdiction)" type="button" class="btn btn-danger btn-sm" :disabled="!isRaUpdateSuccess">Update RA</button>
                  </div>
                </div>
              </div>
            </template>
          </CollapsiblePanel>
        </div>

        <div @click="showFKAs = !showFKAs">
          <h2 class="header">FKAs (Formerly Known As)</h2>
        </div>
        <div class="box" v-if="showFKAs">
          <div class="row" v-for="(fka, index) in fkas" v-bind:key="index">
            <div class="col">
              <dt>FKA name</dt>
              <dd>{{ fka.fka_name }}</dd>
            </div>
          </div>
        </div>

        <div @click="showEvents = !showEvents">
          <h2 class="header">Events</h2>
        </div>
        <div class="box" v-if="showEvents">
          <div v-for="(jurisdiction, index) in jurisdictions" v-bind:key="jurisdiction.jurisdiction_code">
            <dd>{{ jurisdiction.jurisdiction_code }}
              {{ jurisdiction.domestic_flag ? '(Domestic)' : '(Foreign)' }}</dd>
            <div v-if="eventConfigs[jurisdiction.jurisdiction_code] !== undefined && eventConfigs[jurisdiction.jurisdiction_code].length > 0">
              <div v-if="jurisdiction.jurisdiction_filing_date">
                <div class="col" v-if="jurisdiction.jurisdiction_annual_report_date">
                  <dt>Annual Report Date</dt>
                  <dd>{{ jurisdiction.jurisdiction_annual_report_date }}</dd>
                </div>
                <div class="col" v-if="jurisdiction.jurisdiction_initial_report_date">
                  <dt>Initial Report Date</dt>
                  <dd>{{ jurisdiction.jurisdiction_initial_report_date }}</dd>
                </div>
              </div>
              <div v-else>Cannot display events until a filing date is set.</div>
            </div>
            <div class="row" v-else>
              <span class="col">
                There are no events configured for {{ businessEntity.business_entity_type }} entities in
                {{ jurisdiction.jurisdiction_code }}
              </span>
            </div>
            <!-- display the english version of the config rules -->
            <div class="row" v-if="eventConfigs[jurisdiction.jurisdiction_code] !== undefined && eventConfigs[jurisdiction.jurisdiction_code].length > 0">
              <div class="col">
                <br>
                <span v-for="(c, i) in eventConfigs[jurisdiction.jurisdiction_code]" v-bind:key="i">
                <i color="grey">{{ c.in_english }}</i><br>
              </span>
              </div>
            </div>
            <hr v-if="index < jurisdictions.length - 1" />
          </div>
        </div>

        <div @click="showOwnership = !showOwnership">
          <h2 class="header">Ownership</h2>
        </div>
        <div class="box" v-if="showOwnership">
          <div v-if="isLLC" class="row">
            <div class="col">
              <dt>Management Structure</dt>
              <dd v-if="businessEntity.business_entity_data.manager_type">{{ businessEntity.business_entity_data.manager_type | managementStructurePresenter }}</dd>
              <dd v-else-if="businessEntity.management_structure">{{ businessEntity.management_structure | managementStructurePresenter }}</dd>
              <dd v-else>None on file.</dd>
            </div>
          </div>
          <div class="row">
            <div
              v-if="entity_members !== undefined && (isLLC || isSoleProp)"
              class="col"
            >
              <dt>Members</dt>
              <dd v-if="entity_members.length > 0">
                <Person
                  v-for="(member, index) in entity_members"
                  v-bind:key="index"
                  :person="member.contact"
                  :showIndividualName="true"
                  :view="businessEntityView"                >
                </Person>
              </dd>
              <dd v-else>No members on file.</dd>
            </div>
            <div
              v-if="businessEntity.management_structure === 'all_managers' && entity_managers !== undefined && (isLLC || isSoleProp)"
              class="col"
            >
              <dt>Managers</dt>
              <dd v-if="entity_managers.length > 0">
                <Person
                  v-for="(manager, index) in entity_managers"
                  v-bind:key="index"
                  :person="manager.contact"
                  :showIndividualName="true"
                  :isManager="true"
                  :view="businessEntityView"
                >
                </Person>
              </dd>
              <dd v-else>No managers on file.</dd>
            </div>

            <div v-if="(entity_directors !== undefined) && isCorp" class="col">
              <dt>Directors</dt>
              <dd v-if="entity_directors.length > 0">
                <Person v-for="(director, index) in entity_directors" :person="director.contact" v-bind:key="index" >
                </Person>
              </dd>
              <dd v-else>No directors on file.</dd>
            </div>
            <div v-if="(entity_officers !== undefined) && isCorp" class="col">
              <dt>Officers</dt>
              <dd v-if="entity_officers.length > 0">
                <Person v-for="(officer, index) in entity_officers" :person="officer.contact" v-bind:key="index" >
                </Person>
              </dd>
              <dd v-else>No officers on file.</dd>
            </div>
          </div>
        </div>

        <div @click="showAddresses = !showAddresses">
          <h2 class="header">Addresses</h2>
        </div>
        <div class="box" v-if="showAddresses">
          <div class="row">

            <div class="col-md-6" v-for="(address) in addresses" v-bind:key="address.address_type">
              <dt v-if="address.address_type === 'virtual'">{{ address.address_type }} <span v-if="!businessEntity.has_vba" class="canceled">Canceled</span></dt>
              <dt v-else>{{ address.address_type }}</dt>
              <Person :person="address.address" :addressType="address.address_type" >
              </Person>
            </div>
          </div>
        </div>

        <div @click="showContact = !showContact">
          <h2 class="header">Contacts</h2>
        </div>
        <div class="box" v-if="showContact">
          <div class="row">
            <div class="col">
              <h5>Contact</h5>
              <Person v-if="primary_contact !== undefined" :person="primary_contact.contact"  >
              </Person>
              <dd v-else>None on file.</dd>
            </div>
            <div class="col" v-if="sop_contact">
              <h5>Service of Process Contact</h5>
              <Person :person="sop_contact.contact" >
              </Person>
            </div>
          </div>
        </div>
      </div>
    </div>
    <UpdateRaToZBModal
      :isRaUpdateSuccess="isRaUpdateSuccess"
      :isRaUpdateSubmitting="isRaUpdateSubmitting"
      :modalData="modalData"
      @closeRaModal="closeRaModal"
      @updateRAService="updateRAService(modalData)"
      v-if="isRaModalOpen" />
    <RaTrackingModal
      v-if="isTrackingModalOpen"
      :modalData="trackingModalData"
      @closeTrackingModal="closeTrackingModal"/>
  </div>
</template>

<script>
import {
  computeFullNameOfPersonMixin, naicsCodesMixins, getContactTitlesMixin, managementStructureMixin, hasPermissionMixin
} from '../mixins'
import { mapActions, mapGetters, mapState } from 'vuex';
import CollapsiblePanel from '../common/collapsible-panel';
import Person from '../common/person';
import UpdateRaToZBModal from './UpdateRaToZBModal';
import RaTrackingModal from './RaTrackingModal'
import SlotCopy from "@/components/v2/common/Generics/SlotCopy.vue";

export default {
  name: 'BusinessEntityView',

  components: {
    SlotCopy,
    RaTrackingModal,
    CollapsiblePanel,
    Person,
    UpdateRaToZBModal
  },

  mixins: [computeFullNameOfPersonMixin, naicsCodesMixins, getContactTitlesMixin, managementStructureMixin, hasPermissionMixin],

  data() {
    return {
      isTrackingModalOpen: false,
      trackingModalData: {},
      isRaModalOpen: false,
      isRaUpdateSubmitting: false,
      isRaUpdateSuccess: true,
      modalData: {},
      raEffectiveDatetime: null,
      showFilingInfo: true,
      showEvents: true,
      showOwnership: true,
      showAddresses: true,
      showContact: true,
      showFKAs: true,
      timeoutId: null,
      businessEntityView: 'businessEntityView'
    };
  },
  async created() {
    this.timeoutId = setTimeout(() => {
      this.fetchData();
    }, 1000); // Delay the fetchData call by 1 second eo ensure data is fetched before creation
  },
  beforeDestroy() {
    if(this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  },
  computed: {
    ...mapState({
      businessEntities: (state) => state.businessEntities.businessEntities,
      businessEntity: (state) => state.businessEntities.businessEntity,
      businessEntityTasks: (state) => state.businessEntities.businessEntityTasks,
      jurisdictions: (state) => state.businessEntities.jurisdictions,
      eventConfigs: (state) => state.businessEntities.eventConfigs,
      addresses: (state) => state.businessEntities.addresses,
      fkas: (state) => state.businessEntities.fkas,
      contacts: state => state.businessEntities.contacts,
      directors: state => state.businessEntities.directors,
      sopContact: state => state.businessEntities.sopContact,
      managers: state => state.businessEntities.managers,
      members: state => state.businessEntities.members,
      officers: state => state.businessEntities.officers,
      primaryContact: state => state.businessEntities.primaryContact,
      raAddresses: (state) => state.orders.raAddresses,
      registeredAgents: (state) => state.businessEntities.registeredAgents
    }),
    ...mapGetters({
      getBusinessEntityUuid: 'businessEntities/getBusinessEntityUuid'
    }),
    isLLC: {
      get() {
        return this.$store.state.businessEntities.businessEntity.business_entity_type === 'llc';
      }
    },
    isSoleProp: {
      get() {
        return this.$store.state.businessEntities.businessEntity.business_entity_type === 'sole_prop';
      }
    },
    isCorp: {
      get() {
        return this.$store.state.businessEntities.businessEntity.business_entity_type === 'corp';
      }
    },
    hasRA: {
      get() {
        return this.$store.state.businessEntities.businessEntity.has_ra;
      }
    },
    entity_members() {
      return this.members ?? []
    },
    entity_managers() {
      return this.managers ?? []
    },
    primary_contact() {
      if(this.primaryContact.length > 0) {
        return this.primaryContact[0]
      }
      return []
    },
    sop_contact() {
      // There will only be sop contact
      if (this.sopContact.length > 0) {
        return this.sopContact[0]
      }
      return []
    },
    entity_directors() {
      return this.directors ?? []
    },
    entity_officers() {
      return this.officers ?? []
    },
    filingAction() {
      if (this.jurisdictions.length === 0) {
        return 'None';
      }
      const action = this.jurisdictions[0].filing_action;
      return action[0].toUpperCase() + action.slice(1);
    },
    jurisdictionsWithZbRa() {
      return this.businessEntityTasks
        .filter((task) => (task.order_task_type === 'registered_agent_service'
                         && !['canceled'].includes(task.order_task_status)))
        .map((task) => task.order_task_jurisdiction);
    },
    jurisdictionsWithZbWfc() {
      return this.businessEntityTasks
        .filter((task) => (task.order_task_type === 'worry_free_service'
                         && !['canceled'].includes(task.order_task_status)))
        .map((task) => task.order_task_jurisdiction);
    },
    registered_agents() {
      return this.registeredAgents;
    },
  },
  watch: {
    // To avoid a manual refresh to hydrate the page, watch for changes in the business
    // entity UUID to re-fetch data when it changes.
    getBusinessEntityUuid(newUuid, oldUuid) {
        if (newUuid !== oldUuid) {
            this.fetchData();
        }
    }
  },
  methods: {
    ...mapActions('businessEntities', ['updateRegisteredAgentContact', 'createAddressContactJourney']),
    ...mapActions({
      fetchDirectors: 'businessEntities/fetchDirectors',
      fetchRaAgentAddresses: 'orders/fetchRegisteredAgentAddresses',
      fetchSopContact: 'businessEntities/fetchSopContact',
      fetchManagers: 'businessEntities/fetchManagers',
      fetchMembers: 'businessEntities/fetchMembers',
      fetchOfficers: 'businessEntities/fetchOfficers',
      fetchPrimaryContact: 'businessEntities/fetchPrimaryContact',
      fetchRegisteredAgents: 'businessEntities/fetchRegisteredAgents'
    }),
    // fetchData consolidates multiple API calls to streamline data loading and error handling.
    async fetchData() {
        const uuid = this.getBusinessEntityUuid; // Ensure UUID is fetched once and reused, reducing redundancy.
        if (!uuid) {
            return;
        }
       try {
            await Promise.all([
                this.fetchDirectors({ businessEntityUUID: uuid }),
                this.fetchManagers({ businessEntityUUID: uuid }),
                this.fetchMembers({ businessEntityUUID: uuid }),
                this.fetchOfficers({ businessEntityUUID: uuid }),
                this.fetchPrimaryContact({ businessEntityUUID: uuid }),
                this.fetchSopContact({ businessEntityUUID: uuid }),
                this.fetchRegisteredAgents({ businessEntityUUID: uuid }),
                this.fetchRaAgentAddresses()
            ]);
        } catch (error) {
          // eslint-disable-next-line no-empty
        }
    },
    closeRaModal() {
      this.isRaUpdateSubmitting = false
      this.isRaModalOpen = false
    },
    closeTrackingModal() {
      this.isTrackingModalOpen = false
    },
    jurisdictionHasVaStatePin(jurisdiction) {
      const extraStateInfo = jurisdiction.extra_state_info;
      return extraStateInfo
        && Object.keys(extraStateInfo).length > 0
        && extraStateInfo.state_pin;
    },
    jurisdictionHasCaElectiveCeo(jurisdiction) {
      const extraStateInfo = jurisdiction.extra_state_info;
      return extraStateInfo
        && Object.keys(extraStateInfo).length > 0
        && extraStateInfo.elective_ceo
        && Object.keys(extraStateInfo.elective_ceo).length > 0;
    },
    openRaModal(jurisdiction) {
      this.modalData = {
        jurisdiction: jurisdiction
      }
      this.isRaModalOpen = true
    },
    registeredAgentData(jurisdiction, key) {
      // As of 2021-10-27 there is ongoing debate/confusion on whether we should show registered agent info
      //  for entities that are not subscribed to zenbusiness ra service
      // We originally pulled the ra information from the ordertask for the registered agent service
      // After a refactor, this now pulls registered agent information straight from contacts
      //  so registered_agents actually contains ra information for jurisdictions that may not be subscribed
      //  to ZenBusiness' ra service
      // Pending a decision, we preserve the prior logic, and avoid showing ra information if the jurisdiction
      //  doesn't have a subscription to the ra service
      const registeredAgents = this.registered_agents;
      if (this.jurisdictionsWithZbRa.includes(jurisdiction)) {
        const raInState = registeredAgents?.find(ra => ra.contact?.state === jurisdiction);
        return raInState ? raInState.contact[key] : null;
      }
      return null;
    },
    registeredAgentAddress(jurisdiction) {
      const address1 = this.registeredAgentData(jurisdiction, 'address_1');
      const address2 = this.registeredAgentData(jurisdiction, 'address_2');
      const city = this.registeredAgentData(jurisdiction, 'city');
      const state = this.registeredAgentData(jurisdiction, 'state');
      const zip = this.registeredAgentData(jurisdiction, 'zip');

      if (!address1 && !address2 && !city && !state && !zip) {
        return 'None on file';
      }

      return `${address1 ? `${address1}<br>` : ''}
        ${address2 ? `${address2}<br>` : ''}
        ${city && state ? `${city}, ${state}` : `${city || ''}${state || ''} `}
        ${zip || ''}`;
    },
    needUpdateRAService (jurisdiction) {
      let hasRAService = this.jurisdictionsWithZbRa.includes(jurisdiction.jurisdiction_code)
      const registeredAgents = this.registered_agents;
      const raInState = registeredAgents?.find(ra => ra.contact?.state === jurisdiction.jurisdiction_code);

      if (hasRAService) {
        let raContactObj = raInState?.contact;
        let raState = raContactObj?.state;
        let raContactCompanyName = raContactObj?.company_name;

        let expectedCompanyName = this.raAddresses?.[jurisdiction.jurisdiction_code]?.['registered_agent_jurisdiction_address']['company_name']

        return raState && raContactCompanyName && (raContactCompanyName !== expectedCompanyName)
      }
      return false
    },
    updateRAService (modalData) {
      this.isRaUpdateSubmitting = true
      const {
        raEffectiveDatetime: effectiveDateTime,
        jurisdiction: { jurisdiction_uuid: jurUuid, jurisdiction_code: jurCode }
      } = modalData;

      this.updateRegisteredAgentContact({jurisdictionUuid: jurUuid, effectiveDatetime: effectiveDateTime, jurisdictionCode: jurCode})
        .then( response => {
          if (response?.status === 201) {
            this.closeRaModal()
          } else {
            this.isRaUpdateSuccess = false
          }
        })
    },
    canEditRAtoZBRegisterAgent () {
      return this.hasPermission('access:zb-registered-agent')
    },
    showExternalRaId (jurisdiction) {
      const entityHasRaService = this.jurisdictionsWithZbRa.includes(jurisdiction.jurisdiction_code)
      const registeredAgents = this.registered_agents;
      const raInState = registeredAgents?.find(ra => ra.contact?.state === jurisdiction.jurisdiction_code);
      const raContactObj = raInState?.contact;
      const raContactCompanyName = raContactObj?.company_name;

      return entityHasRaService && jurisdiction.external_ra_company_id &&
      raInState && raContactCompanyName && raContactCompanyName.toLowerCase().includes('agents')
    }
  }
};
</script>

<style lang="scss" scoped>
  dt {
    font-size: rem(17);
    color: #a9a9a9;
  }
  .col {
    margin-bottom: 20px;
  }
  .canceled {
    padding-left: 10px;
    color: red;
    font-size: 17px;
  }
  .link {
    color: #138496;
  }
  .ra {
    font-size: .875em;
    font-weight: 700;
    padding-top: .5em;
  }
  .text-smaller {
    font-size: .875em;
  }
</style>
